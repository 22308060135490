.login-screen {
    /*background-image: url(/img/login/login.jpg);*/
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.login-form-bg {
    background: rgba(25, 33, 49, 0.4);
    color: #ffffff;
}
